import React from "react";


import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from "./comps/App";

import { BrowserRouter } from "react-router-dom";

// import { createTheme  ,ThemeProvider } from "@mui/material/styles";

import { Provider } from "react-redux";
import store from './app/store'

import './index.css'

import ToggleColorModeProvider from "./utils/ToggleColorMode";


// const theme = createTheme({});
// const root = ReactDOM.createRoot(document.getElementById("root"));
const root = createRoot(document.getElementById('root'));


root.render(
  <Provider store={store}>
  <ToggleColorModeProvider>
  {/* <ThemeProvider theme={theme}> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  {/* </ThemeProvider> */}
  </ToggleColorModeProvider>
 
  </Provider>
 
);
