import { makeStyles } from "@mui/styles";
const drawerWidth = 240;

export default makeStyles((theme) => ({

 
  toolbar: {
    height: "80px",
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "240px",
    // width: "100%",
    // width:`calc(100% - ${drawerWidth}px)`,


    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      flexWrap: "wrap",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display:'none',
    },
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,


      flexShrink: "none",
    },

    drawerPaper: {
      width: drawerWidth,


    },
  },

  icon: {
    fontSize:'30px !important'
  }
}));
