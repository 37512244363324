import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    // flexDirection: "column",
  },

  toolbar: {
    height: "70px",
  },
  content: {
    padding: "1em",
    flexGrow: "1",
    width: "100%",
  },
}));
