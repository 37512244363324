import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const youtubeApikey = process.env.REACT_APP_YOUTUBE_KEY

 const youtubeSearchHeaders = {
    // 'X-RapidAPI-Key': '6cb8c3cc03msh358c2efbfd6b7aep18782cjsn9cc6c0d50ddc',
    'X-RapidAPI-Key': youtubeApikey,
    'X-RapidAPI-Host': 'youtube-search-and-download.p.rapidapi.com'
  }

const baseUrl =  'https://youtube-search-and-download.p.rapidapi.com'
  const createRequest = (url)=>({url,headers:youtubeSearchHeaders});

  export const youtubeSearchApi= createApi({
    reducerPath: 'youtubeSearchApi',
    baseQuery: fetchBaseQuery({baseUrl:baseUrl}),
    endpoints:(builder)=>({
        getVideosForExercise:builder.query({
            query:(exerciseName)=> createRequest(`/search?query=${exerciseName} exercise`)
        })
    })
  });

  export const {useGetVideosForExerciseQuery} =  youtubeSearchApi