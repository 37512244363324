import React from 'react'

import useStyles from './styles'

import {  Grid, Grow,  Button, Stack,Typography } from "@mui/material";

import { Link } from "react-router-dom";

const Exercise = ({exercise,i}) => {

    const classes = useStyles()


  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} className={classes.exercise} >
        <Grow in key={i} timeout={(i+1)*250} >
            <Link
                className={classes.links} to={`/exercise/${exercise.id}` } 

            > 
            <img
                src={exercise.gifUrl}
                alt={exercise.name}
                className={classes.image} 
                loading="lazy"
            />
       {/* <Stack direction='column'>
         <Button className={classes.button} sx={{background:'#FFA9A9'}} >
            {exercise.bodyPart}
         </Button>
         <Button className={classes.button}  sx={{background:'#FCC757'}} >
            {exercise.target}
         </Button>

       </Stack> */}

       <Typography ml='21px' color='#000' fontWeight='bold' className={classes.title}>
        {exercise.name}
       </Typography>
            </Link>
        </Grow>
    </Grid>
  )
}

export default Exercise