import React, { useState } from "react";

import {
  AppBar,
  IconButton,
  Toolbar,
  Drawer,
  Button,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import {
  AccountCircle,
  Menu,
  Brightness4,
  Brightness7,
  Home as HomeIcon,
} from "@mui/icons-material";

import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "./styles";

import { Search, SideBar } from "../index";
import { ColorModeContext } from "../../utils/ToggleColorMode";

import { useContext } from "react";

const NavBarForExercises = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const colorMode = useContext(ColorModeContext)

  return (
    <div>
      <AppBar  position="fixed" color="error">
        <Toolbar className={classes.toolbar}>
          {isMobile && (
            <IconButton
              color="inherit"
              edge="end"
              style={{ outline: "none" }}
              onClick={() => setMobileOpen((prev) => !prev)}
              className={classes.menuButton}
            >
              <Menu />
            </IconButton>
          )}
          <IconButton
            color="inherit"
            edge="end"
            sx={{ ml: 1 }}
            onClick={colorMode.toggleColorMode}
          >
            {theme.palette.mode === "dark" ? <Brightness7 className={classes.icon} /> : <Brightness4 className={classes.icon} />}
          </IconButton>
          {/* {!isMobile && <Search />} */}

          <Link style={{ textDecoration: "none", color: "white" }} to="/">
            <IconButton color="inherit" edge="end" sx={{ ml: 1 }}>
              <HomeIcon className={classes.icon} />
            </IconButton>
          </Link>

          {/* {isMobile && <Search />} */}
        </Toolbar>
      </AppBar>
      <div className={classes.drawer}>
        {isMobile ? (
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={() => setMobileOpen((prev) => !prev)}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            <SideBar setMobileOpen={setMobileOpen} />
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            classes={{ paper: classes.drawerPaper }}
            open
          >
            <SideBar setMobileOpen={setMobileOpen} />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default NavBarForExercises;
