import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";



// const options = ['Exercises by bodyparts', 'Exercises by  target muscles', "Exercises by equipment"];


const ComboBox = ({value,setValue,options}) => {

  
        // const [value, setValue] = useState(options[0]);
        const [inputValue, setInputValue] = useState('');
  return (
    <Autocomplete
    value={value}
    onChange={(event, newValue) => {
      setValue(newValue);
    }}
    inputValue={inputValue}
    onInputChange={(event, newInputValue) => {
      setInputValue(newInputValue);
    }}
    id="controllable-states-demo"
    options={options}
    sx={{ width: 240 }}
    renderInput={(params) => <TextField {...params} label="Choose Exercises by" />}
  />
  );
};

export default ComboBox;
