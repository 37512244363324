import React from "react";

import { Link } from "react-router-dom";

import { Stack, Typography,IconButton } from "@mui/material";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import {

  Brightness4,
  Brightness7,

} from "@mui/icons-material";
import { ColorModeContext } from "../../utils/ToggleColorMode";
import { ThemeProvider, useTheme } from "@mui/material/styles";


import Logo from "../../assets/images/Logo.png";

import useStyles from "./styles";
import { useContext } from "react";


const NavbarForHome = () => {
  const classes = useStyles();
  const colorMode = useContext(ColorModeContext)
 const theme= useTheme();
   return (
    <Stack
      className={classes.stack}
      direction="row"
      sx={{
        spacing: { md:'200px', sm: "100px", xs: "30px" },
        mt: { sm: "40px", xs: "40px" },
      }}
      px="0px"
    >
      <Link to="/">
        {/* <img src={Logo} alt="Logo" className={classes.logo} /> */}
        <FitnessCenterIcon className={classes.logo} sx={{  fontSize:'50px'  , color:"#FF2625"}} />

      </Link>
      <Stack
        direction="row"
        spacing="40px"
        alignItems="flex-end"
        className={classes.navContainer}
        // fontSize="24px"
        sx={{marginBottom: '10px',spacing:{  sm:'10px', xs: "10px"}}}
      >
        <Link
          to="/"
          style={{ borderBottom: "3px solid #FF2625" }}

          className={classes.Link}
        >
           <Typography  color="textPrimary" className={classes.nav} >Home</Typography>
        </Link>
        <Link to="/exercises" className={classes.Link}>
          
          <Typography color="textPrimary" className={classes.nav} >Exercises</Typography>

        </Link>
        <IconButton
            color="inherit"
            edge="end"
            sx={{ ml: 1  }}
            
            onClick={colorMode.toggleColorMode}
            className={classes.LinkMode}
          >
            {theme.palette.mode === "dark" ? <Brightness7 className={classes.icon} /> : <Brightness4 className={classes.icon} />}
          </IconButton>
      </Stack>
    </Stack>
  );
};

export default NavbarForHome;
