import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    marginTop: "203px",
    padding: "20px",
    marginBottom:'40px',
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },

  name: {
    color: "#FF2625",
    textTransform: "capitalize",
  },
  stacks: {
    // justifyContent: "flex-start",
    justifyContent: "space-around",

    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      gap: "110px",
    },

    [theme.breakpoints.down("sm")]: {
      gap: "0px",
    },
  },
  img:{
    // width: "100%",
    height: "200px",

  },
  videoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "300px",
    height: "300px",
    textDecoration: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  video: {
    width: "50%",
    height: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "90%",
    },
  },
}));
