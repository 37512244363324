import React ,{useRef} from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import { CssBaseline, Box } from "@mui/material";

import {
  StartPage,
  Home,
  ExerciseInformation,
  Exercises,
  NavBarForExercises,
} from "./index";




import useStyles from "./styles";

import useAlan from "./Alan";

const App = () => {
  const classes = useStyles();
  useAlan();
  const alanBtnContainer = useRef();

  return (
    // <Box width='400px' sx={{width:{xl:"1488px"}}} m='auto' >
    // <Box  className={classes.box}  >

    <div 
    
    // className={classes.container}
    >
      <CssBaseline />

      {/* <NavBarForExercises />
      <main className={classes.content}>
        <div className={classes.toolbar} /> */}

        <Routes>
          {/* <Route path="/exercises" element={<Home />} />
          <Route path="/" element={<StartPage />} /> */}

          {/* <Route path="/exercise/:id" element={<ExerciseInformation />} /> */}

          <Route exact path='/exercises'  element={ <Exercises/> }/>
          <Route path='/' element={<StartPage/>} />
          <Route path="/exercise/:id"   element={ <ExerciseInformation />} />

          
        </Routes>
      {/* </main> */}

      <div ref={alanBtnContainer} />
    </div>
    // </Box>
  );
};

export default App;
