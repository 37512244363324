import React from "react";

import { NavbarForHome } from "../index";

import { Box, Stack, Typography, Button ,Grid } from "@mui/material";
import { Link } from "react-router-dom";
import useStyles from "./styles";
// import HeroBannerImage from '../../assets/images/banner.png'
import HeroBannerImage from '../../assets/images/banner1.avif'

const StartPage = () => {
  const classes = useStyles();
  return (
    <Grid container 
    spacing={2}
    >
    
    <Grid className={classes.main} item  lg={5} md={5}  >
    <NavbarForHome />
      {/* <Box sx={{mt:{lg:'212px',xs:'70px'},ml:{sm:'50px'}}} position="relative" p="20px" > */}
      <Box className={classes.container}>
        <Typography fontWeight={600} variant="h4" className={classes.title}>
          Fitness Club
        </Typography>
        <Typography
          fontWeight={700}
          sx={{ fontSize: { lg: "44px", xs: "40px" } }}
        >
          Sweat , Smile <br /> and Repeat
        </Typography>
        <Typography fontSize="22px" lineHeight="35px" mb="30px">
          Check out the most effective exercises
        </Typography>
        <Link to="/exercises" className={classes.link}>
          <Button variant="contained" color="error" size="large" className={classes.button} >
            {" "}
            Explore Exercises{" "}
          </Button>
        </Link>
      </Box>
    </Grid>
    <Grid item lg={7} md={5} >
    <img src={HeroBannerImage} alt="banner" className={classes.banner}  />
    </Grid>
      
      <Typography fontWeight={700} fontSize='200px' className={classes.exercise}>
        Exercise
      </Typography>

    </Grid>
  );
};

export default StartPage;
