import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  exercise: {
    padding: "10px",
  },
  links: {
    alignItems: "center",
    fontWeight: "bolder",
    marginBottom: "20px",
   
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      flexDirection: "column",
      textDecoration: "none",
    },
    '&:hover': {
        cursor: "pointer",
    }
  },
  image: {
    borderRadius:"20px",
    height:'300px',
    width:"300px",



    marginBottom:'10px',
    '&:hover': {
        Transform:'scale(1.05)'
    }
  },
  button:{
    marginLeft:'21px',
    color:'#fff',
    fontSize:'14px',
    borderRadius:'20px',
    textTransform:'capitalize',

  },
  title:{
    color:theme.palette.text.primary,
    textOverflow:'ellipsis',
    width:'300px',
    whiteSpace:'nowrap',
    overflow:'hidden',
    marginTop:'10px',
    marginBottom:'0',
    textAlign:'center',
    textTransform:'capitalize',
    [theme.breakpoints.down("md")]: {
       
        width:'300px',


    }

  }
}));
