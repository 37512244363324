import React, { useEffect, useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  Box,
  CircularProgress,
  Typography
} from "@mui/material";

 import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

import {
  useGetBodyPartsQuery,
  useGetMuscleTypeQuery,
  useGetEquipmentNamesQuery,
} from "../../services/ExerciseDB";

import {
  SelectEquipmentName,
  SelectPartBody,
  SelectMuscleType,
} from "../../features/partBodyOrMuscleTypeOrEquipmentName";

import { Link } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { ComboBox } from "../index";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../../assets/images/Logo-1.png";

const options = ["by body parts", "by target muscles", "by equipment"];

const SideBar = ({ setMobileOpen }) => {
  const { partBodyOrMuscleTypeOrEquipmentNameReducer } = useSelector(
    (state) => state.currentPartBodyOrMuscleTypeOrEquipmentName
  );

  const [value, setValue] = useState(" ");

  const { data, isFetching, error } = useGetBodyPartsQuery();

  const { data: musclesData, isFetching: isMuslcesFetching } =
    useGetMuscleTypeQuery();
  const { data: ExersicesData, isFetching: isExersicesFetching } =
    useGetEquipmentNamesQuery();

    console.log(ExersicesData);
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    setMobileOpen(false);
  }, [partBodyOrMuscleTypeOrEquipmentNameReducer]);

  return (
    <div>
      <Link to="/" className={classes.imageLink}>
        {/* <img className={classes.image} src={Logo} alt="logo" /> */}
        
        <FitnessCenterIcon sx={{ fontSize:'40px' , color:"#FF2625"}} />
        <Typography variant="h6" color="error" p=' 2px 10px' >Golds Gym</Typography>
      </Link>
      <Divider />
      <ComboBox value={value} setValue={setValue} options={options} />

      <List>
        <ListSubheader sx={{ fontSize: "30px" }}>Exercises</ListSubheader>




       {value === "by body parts"  && data?.map((name, index) => (
          <Link key={index} className={classes.links} to="/exercises">
            <ListItem
              onClick={() =>
               {
                 dispatch(SelectPartBody(name))
                 console.log(name)

               }
              }
              button
            >
              <ListItemText primary={name} />
            </ListItem>
          </Link>
        ))}

        {value === " "  && data?.map((name, index) => (
          <Link key={index} className={classes.links} to="/exercises">
            <ListItem
              onClick={() =>
               {
                 dispatch(SelectPartBody(name))
                 console.log(name)

               }
              }
              button
            >
              <ListItemText primary={name} />
            </ListItem>
          </Link>
        ))}

        {value === "by target muscles" && musclesData?.map((name, index) => (
          <Link key={index} className={classes.links} to="/exercises">
            <ListItem
              onClick={() =>
                {
                  dispatch(SelectMuscleType(name))
                  console.log(name)

                }
              }
              button
            >
              <ListItemText primary={name} />
            </ListItem>
          </Link>
        ))}

        
        {value === "by equipment" && ExersicesData?.map((name, index) => (
          <Link  key={index} className={classes.links} to="/exercises">
            <ListItem 
              onClick={() =>
              { 
                 dispatch(SelectEquipmentName(name))
                console.log(name)}
              }
              button
            >
              <ListItemText  className={classes.name}  primary={name} />
            </ListItem>
          </Link>
        ))}  


{/* this make a bad request */}


        {/* {(value === "by body parts"
          ? data
          : value === "by target muscles"
          ? musclesData
          : value === "by equipment"
          ? ExersicesData
          : data
        )?.map((name, index) => (
          <Link key={index} className={classes.links} to="/exercises">
            <ListItem
              onClick={() =>

                {
                
                return (
                  value === "by body parts"?
                dispatch(SelectPartBody(name)) : value === "by target muscles"?
                dispatch(SelectMuscleType(name)): dispatch(SelectEquipmentName(name)) )

                 }
               
              
              }
              button
            >
              <ListItemText primary={name} />
            </ListItem>
          </Link>
        ))} */}
      </List>
    </div>
  );
};

export default SideBar;
