import { configureStore } from "@reduxjs/toolkit";

import {exerciseApi} from '../services/ExerciseDB'
import { youtubeSearchApi } from "../services/YoutubeSearch";

import partBodyOrMuscleTypeOrEquipmentNameReducer from '../features/partBodyOrMuscleTypeOrEquipmentName'


export default configureStore({
    reducer:{
        [exerciseApi.reducerPath]:exerciseApi.reducer,
        [youtubeSearchApi.reducerPath]:youtubeSearchApi.reducer,
        currentPartBodyOrMuscleTypeOrEquipmentName:partBodyOrMuscleTypeOrEquipmentNameReducer,
    }
})

