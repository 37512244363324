import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({

    stack:{
        // width:'100%',
        // display:'flex',
        //  [theme.breakpoints.down("lg")]:{
        //  justifyContent: "space-between",
        //  }
    },
    navContainer:{
  width:'100%',
    },
  Link: {
    textDecoration: "none",
    color: "#3A1212",
  },
  LinkMode: {
    // marginLeft:'40px',
    position: "absolute",
    top: "0px",
    left: "180px",

    [theme.breakpoints.down("lg")]:{
        top:'8px',
        left:'30px',
    },
    // '@media (min-width:820px)': { // eslint-disable-line no-useless-computed-key
    //     top:'8px',
    //     left:'200px',
    //   },
    [theme.breakpoints.down("md")]: {
      top: "5px",
    //   left: "-15px",
      left: "300px",

    },
    [theme.breakpoints.down("sm")]: {
      top: "8px",
      left: "-20px",

    },
  },
  icon: {
    fontSize: "30px !important",
  },

  logo: {
    // width:'48px',
    // height:'48px',
    // margin:"0 20px",
    marginRight: "70px",
    marginLeft: "20px",
    marginTop: "5px",

    [theme.breakpoints.down("md")]: {
      marginRight: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "30px",
    },
  },
  nav: {
    fontSize: "30px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !important",
    },
  },
}));
