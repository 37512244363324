import {makeStyles} from '@mui/styles';


export default makeStyles ((theme)=>({

    imageLink:{
        display:'flex',
        justifyContent: 'center',
        padding:'10% 0'
    }
    ,
    image:{
        // width:'70%',
        fontSize:"40px"
    },
    links:{
        color:theme.palette.text.primary,
        textDecoration:'none'
    },

    name:{
        fontSize:"20px !important",
        fontWeight:"bold",
    }
 
}))