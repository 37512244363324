import React, { useEffect, useContext } from "react";

import { ColorModeContext } from "../utils/ToggleColorMode";
import alanBtn from "@alan-ai/alan-sdk-web";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  SelectPartBody,
  SelectMuscleType,
  SelectEquipmentName,
} from "../features/partBodyOrMuscleTypeOrEquipmentName";
const useAlan = () => {

  const colorMode = useContext(ColorModeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const {setmode} =  useContext(ColorModeContext)
   
  useEffect(() => {
    alanBtn({
      key: "2996fd67fb008c4d8c349cb62135c4cf2e956eca572e1d8b807a3e2338fdd0dc/stage",
      onCommand: ({
        command,
        mode,
        bodyPart,
        muscleType,
        equipmentName,
        bodyParts,
        muscleTypes,
        equipmentNames,
      }) => {
        if (command === "chooseBodyPart") {
          const foundBodyPart = bodyParts.find(
            (e) => e.toLowerCase() === bodyPart.toLowerCase()
          );

          if (foundBodyPart) {
            navigate("/exercises");
            dispatch(SelectPartBody(foundBodyPart));
          }
        }  else if (command === "chooseEquipmentName") {
          const foundEquipmentName = equipmentNames.find(
            (e) => e.toLowerCase() === equipmentName.toLowerCase()
          );

          if (foundEquipmentName) {
            navigate("/exercises");
            dispatch(SelectEquipmentName(foundEquipmentName));
          }
        } 
        
        
        else if (command === "chooseMuscleType") {
            const foundMuscleType = muscleTypes.find(
              (e) => e.toLowerCase() === muscleType.toLowerCase()
            );
            if (foundMuscleType) {
              navigate("/exercises");
              dispatch(SelectMuscleType(foundMuscleType));
            }
          }
        
        else if (command === "changeMode") {
          if (mode === "light") {
            colorMode.setmode("light");
          } else {
            colorMode.setmode("dark");
          }
        }
      },
    });
  }, []);
};

export default useAlan;
