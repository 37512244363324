import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({



  searchContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center ",
      width: "100%",
    },
  },

  search:{
    width:'1000px',
    // width: "100%",
    borderRadius:'4px',
    [theme.breakpoints.down("lg")]: {
      width:'300px',

    },
    [theme.breakpoints.down("md")]: {
      width:'280px',

    },

    [theme.breakpoints.down("sm")]: {
      width:'220px',

    }
  },
   input: {
    // color: theme.palette.mode==='light' && 'black',
    fontWeight:'bold',
    border:'none',
    borderRadius:'4px',
    
    filter: theme.palette.mode==='light' && 'invert(1)',
    [theme.breakpoints.down("sm")]: {
        marginTop:'-10px',
        marginBottom:'-10px',
    }
  },

  button:{
    
    textTransform:'none',
    width:'173px',
    height:'56px',
    backgroundColor: "#FF2625 !important",
    color:"#FFF !important",
    fontSize: "20px !important",

    position:'absolute',
    right:'0px',
    '&:hover':{
      color:"#FF2625 !important",
      backgroundColor: "#fff !important",

      border:"1px solid #FF2625 !important",
    },
    [theme.breakpoints.down("sm")]: {
      width:'80px',
      fontSize: "14px !important",

    }


  }
}));
