import { createSlice } from "@reduxjs/toolkit";

export const partBodyOrMuscleTypeOrEquipmentName = createSlice({
  name: "partBodyOrMuscleTypeOrEquipmentName",
  initialState: {
    // partBodyOrMuscleTypeOrEquipmentName: "",
    PartBody:'',
    MuscleType:'',
    EquipmentName:'',
    page: 1,
    searchQuery: "",
  },

  reducers: {
    // selectPartBodyOrMuscleTypeOrEquipmentName: (state, action) => {
    //   state.partBodyOrMuscleTypeOrEquipmentName = action.payload;
    //   state.searchQuery = "";
    // },

    SelectPartBody: (state, action) => {
      state.PartBody= action.payload;
      state.searchQuery = "";
      state.MuscleType = "";
      state.EquipmentName = "";
    },
    SelectMuscleType: (state, action) => {
      state.MuscleType= action.payload;
      state.searchQuery = "";
      state.PartBody = "";
      state.EquipmentName = "";
    },
    SelectEquipmentName: (state, action) => {
      state.EquipmentName= action.payload;
      state.searchQuery = "";
      state.PartBody = "";
      state.MuscleType = "";
    },

    searchExercise: (state, action) => {
      console.log("here", action.payload);
      state.searchQuery = action.payload;
    },
  },
});

export const {  searchExercise, SelectEquipmentName,SelectPartBody,SelectMuscleType } =
  partBodyOrMuscleTypeOrEquipmentName.actions;

export default partBodyOrMuscleTypeOrEquipmentName.reducer;
