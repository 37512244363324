import React from "react";
import { Grid } from "@mui/material";

import useStyles from "./styles";
import {Exercise} from '../index'

const ExercisesList = ({ exercises, numberOfExercises }) => {
  const classes = useStyles();
  return (
    <Grid container  className={classes.exercisesContainer}>
    {exercises?.map((exercise,i)=>(
      <Exercise key={i} exercise={exercise} i={i} />
    )).slice(0,numberOfExercises)}
  </Grid>
  )
  
  
};

export default ExercisesList;
