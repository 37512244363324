import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
  },

  toolbar: {
    height: "70px",
  },
  content: {
    
    padding: "1em",
    flexGrow: "1",
    width: "100%",
  },

  div:{
    width:'1500px',
    // width: "100%",
    borderRadius:'4px',
    [theme.breakpoints.down("lg")]: {
      width:'500px',

    },
    [theme.breakpoints.down("md")]: {
      width:'500px',

    },

    [theme.breakpoints.down("sm")]: {
      width:'370px',

    }
  },
  containerSpaceAround: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    margin: "10px 0 !important",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      flexWrap: "wrap",
    },
  },
  image: {
    borderRadius: "20px",
    // width:'80%',
    width: "800px",

    boxShadow: "0.5em 1em 1em rgb(64,64,70)",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      width: "50%",
      // width:'600px',
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "100%",
      // width:'400px',
      height: "350px",
      marginBottom: "30px",
    },
  },
  btn: {
    borderRadius: "50% !important",
    backgroundColor: "#FF2625 !important",
    width: "70px",
    height: "70px",
    color: "#FFF !important",
    marginRight: "30px !important",
    // [theme.breakpoints.down("md")]:{

    //   width: "20px",
    //   height: "50px",
    // }
  },

  name: {
    textTransform: "capitalize",
    fontSize: "25px !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px !important",
    },
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "15px !important",
    // },
  },

  icon: {
    fontSize: "40px !important",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "20px !important",
    // },
  },
  BtnContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "20px 0",
    textDecoration:'none'
  } , 
  circle :{
    margin:'20rem 40rem',
    [theme.breakpoints.down("md")]:{
      margin:'300px 300px ',
    },
    [theme.breakpoints.down("sm")]:{
      margin:'300px 100px ',
    }
  }

}));
