import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  box: {
    margin: "1rem 2rem",
    [theme.breakpoints.down("md")]: {
      margin: "0",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  toolbar: {
    height: "70px",
  },
  container: {
    display: "flex",
    height: "100%",
  },

  content: {
    // flexGrow: "1",
    padding: "1em",
    // width: "100%",
  },
}));
