import React, { useState } from "react";
import { Typography, Box, Stack, CircularProgress, Modal } from "@mui/material";
import useStyles from "./styles";

const ExerciseVideos = ({ exerciseVideos, name }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [VideoId, setVideoId] = useState();
  console.log(exerciseVideos);

  if (!exerciseVideos?.length) {
    return (
      <Box
        sx={{ margin: " 300px auto" }}

        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size="8rem" />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Typography
        sx={{ fontSize: { lg: "30px", xs: "20px" } }}
        fontWeight={700}
        mb="33px"
      >
        Watch{" "}
        <span style={{ color: "#FF2625", textTransform: "capitalize" }}>
          {name}
        </span>{" "}
        exercise videos
      </Typography>
      <Stack
        className={classes.stacks}
        // sx={{ flexDirection: { lg: 'row' }, gap: { lg: '110px', xs: '0px' }  }}

        direction="row"
      >
        {exerciseVideos?.slice(0, 3)?.map((item, index) => (
          <div
            className={classes.videoContainer}
            key={index}
            onClick={() => {
              setVideoId(item.video?.videoId);
              setOpen(true);
            }}
          >
            <img
              style={{ borderTopLeftRadius: "20px" }}
              src={item.video?.thumbnails[0].url}
              alt={item.video?.thumbnails}
              className={classes.img}
            />
            <Box>
              <Typography
                sx={{ fontSize: { lg: "20px", xs: "18px" } }}
                fontWeight={600}
              >
                {item.video?.title}
              </Typography>
              <Typography fontSize="14px">{item.video?.channelName}</Typography>
            </Box>
          </div>
        ))}
      </Stack>
      <Modal
        closeAfterTransition
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
      >
        <iframe
          className={classes.video}
          autoPlay
          frameBorder="0"
          allow="autoPlay"
          title="exercise video"
          src={`https://www.youtube.com/embed/${VideoId}`}
        />
      </Modal>
    </Box>
  );
};

export default ExerciseVideos;
