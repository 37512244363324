import React, { useEffect, useState } from "react";

import useStyles from "./styles";
import {
  NavBarForExercises,
  Paginate,
  ExercisesList,
  ExerciseVideos,
} from "../index";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Stack,
  CircularProgress,
  useMediaQuery,
  Button,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
// import {AccessibilityNewIcon, CrisisAlertIcon, SelfImprovementIcon} from '@mui/icons-material';
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import {
  useGetExerciseByIdQuery,
  useGetExerciseByMuscleTypeQuery,
} from "../../services/ExerciseDB";

import { useDispatch, useSelector } from "react-redux";

import {
  SelectEquipmentName,
  SelectPartBody,
  SelectMuscleType,
} from "../../features/partBodyOrMuscleTypeOrEquipmentName";

import { useGetVideosForExerciseQuery } from "../../services/YoutubeSearch";
const ExerciseInformation = () => {
  const { id } = useParams();
  const { data, isFetching, error } = useGetExerciseByIdQuery(id);
  const targetMuscle = data?.target;
  const exerciseName = data?.name;
  const dispatch = useDispatch();
  const { data: muscleData, isFetching: isMuscleFetching } =
    useGetExerciseByMuscleTypeQuery(targetMuscle);
  const { data: videosData } = useGetVideosForExerciseQuery(exerciseName);
  console.log(id);
  console.log(data);
  // console.log(videosData)
  // const [Page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const lg = useMediaQuery((theme) => theme.breakpoints.only("lg"));

  const numberOfExercises = lg ? 6 : 8;
  const [exercisesPerPage] = useState(numberOfExercises);
  const classes = useStyles();
  const indexOfLastExercise = currentPage * exercisesPerPage;
  const indexOfFirstExercise = indexOfLastExercise - exercisesPerPage;
  const currentExercises = muscleData?.slice(
    indexOfFirstExercise,
    indexOfLastExercise
  );
  // useEffect(() => {
  //   console.log(data?.id);
  // }, [data]);
  const paginate = (event, value) => {
    setCurrentPage(value);

    window.scrollTo({ top: 1800, behavior: "smooth" });
  };

  // if (isFetching) {
  //   return (
  //     <Box
  //       sx={{ margin: " 200px auto" }}
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //     >
  //       <CircularProgress size="8rem" />
  //     </Box>
  //   );
  // }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Link to="/">SomeThing went wrong</Link>
      </Box>
    );
  }
  // if (isMuscleFetching) {
  //   return (
  //     <Box
  //       sx={{ margin: " 1000px auto" }}
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //     >
  //       <CircularProgress size="8rem" />
  //     </Box>
  //   );
  // }

  return (
    <div>
      <div className={classes.container}>
        <NavBarForExercises />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* <div className={classes.div}></div> */}
          {isFetching ? (
            <Box


              // sx={{ margin: "200px auto" }}
              display="flex"
              justifyContent="center"
              // alignItems="center"
            >
              <CircularProgress size="8rem" />
            </Box>
          ):(
            <div>
              <Grid container className={classes.containerSpaceAround}>
                <Grid
                  item
                  sm={12}
                  lg={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                >
                  <img
                    src={data?.gifUrl}
                    loading="lazy"
                    alt={data?.name}
                    className={classes.image}
                  />
                </Grid>
                <Grid item lg={5}>
                  {/* <Typography variant='h5' gutterBottom > exercise name: {data?.name}</Typography>
            <Typography variant='h5' gutterBottom > the target muscle: {data?.target}</Typography>
            <Typography variant='h5' gutterBottom > the equipment name: {data?.equipment}</Typography> */}

                  <Typography
                    sx={{ fontSize: { lg: "50px", xs: "20px" } }}
                    fontWeight={700}
                    textTransform="capitalize"
                  >
                    {data?.name}
                  </Typography>
                  <Typography sx={{ fontSize: { lg: "24px", xs: "18px" } }}>
                    Exercises keep you strong.{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {data?.name}
                    </span>{" "}
                    bup is one of the best <br /> exercises to target your{" "}
                    {data?.target}. It will help you improve your <br /> mood
                    and gain energy.
                  </Typography>
                  <div>
                    <Link
                      to="/exercises"
                      className={classes.BtnContainer}
                      onClick={() => dispatch(SelectPartBody(data?.bodyPart))}
                    >
                      <Button className={classes.btn}>
                        <AccessibilityIcon className={classes.icon} />
                      </Button>
                      <Typography color="textPrimary" className={classes.name}>
                        Body Part : {data?.bodyPart}
                      </Typography>
                    </Link>
                    <Link
                      to="/"
                      className={classes.BtnContainer}
                      onClick={() =>
                        dispatch(SelectMuscleType(data?.muscleType))
                      }
                    >
                      <Button className={classes.btn}>
                        <CrisisAlertIcon className={classes.icon} />
                      </Button>
                      <Typography color="textPrimary" className={classes.name}>
                        Target muscle : {data?.target}
                      </Typography>
                    </Link>
                    <Link
                      to="/"
                      className={classes.BtnContainer}
                      onClick={() =>
                        dispatch(SelectEquipmentName(data?.equipment))
                      }
                    >
                      <Button className={classes.btn}>
                        <SelfImprovementIcon className={classes.icon} />
                      </Button>
                      <Typography color="textPrimary" className={classes.name}>
                        Equipment :{data?.equipment}
                      </Typography>
                    </Link>
                  </div>
                </Grid>
              </Grid>

              <ExerciseVideos
                exerciseVideos={videosData?.contents}
                name={data?.name}
              />
              <Typography variant="h3" gutterBottom align="center">
                You might also like
              </Typography>

              {muscleData ? (
                <ExercisesList
                  exercises={currentExercises}
                  numberOfExercises={numberOfExercises}
                />
              ) : (
                <Box>Sorry, nothing was found.</Box>
              )}

              <Stack
                sx={{ mt: { lg: "114px", xs: "70px" } }}
                alignItems="center"
              >
                {muscleData?.length > 9 && (
                  <Pagination
                    color="standard"
                    shape="rounded"
                    defaultPage={1}
                    count={Math.ceil(muscleData?.length / exercisesPerPage)}
                    page={currentPage}
                    onChange={paginate}
                    size="large"
                  />
                )}
              </Stack>
            </div>
          )}
          {/* <Paginate currentPage={Page} setPage={setPage} 
         totalPages={ Math.ceil(muscleData?.length/exercisesPerPage) }/> */}
        </main>
      </div>
    </div>
  );
};

export default ExerciseInformation;
