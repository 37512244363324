import React, { useState, useEffect } from "react";

import {
  NavBarForExercises,
  ExercisesList,
  ExerciseInformation,
  Search,
} from "../index";
import { useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  useMediaQuery,
  Typography,
  Stack,
} from "@mui/material";

import { Link } from "react-router-dom";

import useStyles from "./styles";

import {
  useGetAllExercisesQuery,
  useGetAllExercisesForSearchQuery,
} from "../../services/ExerciseDB";

import Pagination from "@mui/material/Pagination";

const Exercises = () => {
  const { PartBody, MuscleType, EquipmentName, searchQuery } = useSelector(
    (state) => state.currentPartBodyOrMuscleTypeOrEquipmentName
  );
  const { data, isFetching, error } = useGetAllExercisesQuery({
    PartBody,
    MuscleType,
    EquipmentName,
    searchQuery,
  });

  const { data: searchData, isFetching: isSearchFetching } =
    useGetAllExercisesForSearchQuery();

  const classes = useStyles();
  const [Exercises, setExercises] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const lg = useMediaQuery((theme) => theme.breakpoints.only("lg"));

  const numberOfExercises = lg ? 18 : 24;
  // const exercisesPerPage = numberOfExercises;
  const [exercisesPerPage] = useState(numberOfExercises);

  const indexOfLastExercise = currentPage * exercisesPerPage;
  const indexOfFirstExercise = indexOfLastExercise - exercisesPerPage;
  const currentExercises = Exercises?.slice(
    indexOfFirstExercise,
    indexOfLastExercise
  );
  const paginate = (event, value) => {
    setCurrentPage(value);

    window.scrollTo({ top: 1800, behavior: "smooth" });
  };

  console.log(data);
  // console.log(error);

  useEffect(() => {
    setExercises(data);
  }, [data]);
  // useEffect(() => {
  //   setExercises(Exercises)
  // }, [Exercises]);

  // if (isFetching) {
  //   return (
  //     <Box
  //     sx={{ margin: " 200px auto" }}
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //   >
  //     <CircularProgress size="8rem" />
  //   </Box> )}

  // }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Link to="/">SomeThing went wrong</Link>
      </Box>
    );
  }

  return (
    <div>
      <div className={classes.container}>
        <NavBarForExercises />
    
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Search setExercises={setExercises} searchData={searchData} />
          {isFetching ? ( 
          <Box
            display="flex"
            justifyContent="center"
            // alignItems="center"
          >
            <CircularProgress size="8rem" />
          </Box>
        ) : <>
          <ExercisesList
            exercises={currentExercises}
            numberOfExercises={numberOfExercises}
          /> 

          <Stack sx={{ mt: { lg: "114px", xs: "70px" } }} alignItems="center">
            {data?.length > 9 && (
              <Pagination
                color="standard"
                shape="rounded"
                defaultPage={1}
                count={Math.ceil(Exercises?.length / exercisesPerPage)}
                page={currentPage}
                onChange={paginate}
                size="small"
              />
            )}
          </Stack>  </>  } 
        </main> 
      </div>
    </div>
  );
};

export default Exercises;
