import React, { useState } from "react";

import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Stack, TextField, Typography ,  CircularProgress,
} from "@mui/material";


import useStyles from "./styles";

import { useGetAllExercisesForSearchQuery } from "../../services/ExerciseDB";

const Search = ({ setExercises ,searchData }) => {
  const classes = useStyles();
  const location = useLocation();

  // const { data,isFetching} = useGetAllExercisesForSearchQuery();

  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    if (search) {
      // const searchedExercises = data?.filter(
      const searchedExercises = searchData?.filter(
        (item) =>
          item.name.toLowerCase().includes(search)  ||
          item.equipment.toLowerCase().includes(search) ||
          item.target.toLowerCase().includes(search) ||
          item.bodyPart.toLowerCase().includes(search)
      );

      // window.scrollTo({ top: 1800, left: 100, behavior: "smooth" });
      setSearch("");
      setExercises(searchedExercises);
      console.log(searchedExercises);
    }
  };

  if (location.pathname !== "/exercises") return null;

  return (
    <Stack alignItems="center" mt="37px" justifyContent="center" p="20px" margin="auto">
      <Typography
        fontWeight={700}
        sx={{ fontSize: { lg: "40px",  xs: "25px" } }}
        mb="44px"
        textAlign="center"
      >
        Awesome Exercises You <br /> Should Know
      </Typography>

      <Box position="relative" mb="72px">
        <TextField
          className={classes.search}

          classes={ { input: classes.Input}}

          // sx={{
          //   input: { fontWeight: "700", border: "none", borderRadius: "4px" },
          //   backgroundColor: "#FFF",
          // }}
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
          placeholder="Search Exercises"
          type="text"
        />
        <Button
          className={classes.button}
          sx={{
         
            // fontSize: { lg: "20px", xs: "14px" },
          }}
          onClick={handleSearch}
        >
          Search
        </Button>
      </Box>
    </Stack>
  );
};

export default Search;
