import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const gymApikey = process.env.REACT_APP_GYM_KEY
const exercisesHeaders = {
  "X-RapidAPI-Key": gymApikey ,
  "X-RapidAPI-Host": "exercisedb.p.rapidapi.com",
};

const baseUrl = 'https://exercisedb.p.rapidapi.com';
// const baseUrl = 'https://exercisedb.p.rapidapi.com/exercises/bodyPartList';



const createRequest = (url) => ({ url, headers: exercisesHeaders });

export const exerciseApi = createApi({
  reducerPath: "exerciseApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl  }),
  endpoints: (builder) => ({
    getAllExercises: builder.query({
      query: ({PartBody,
        MuscleType,
        EquipmentName,
        searchQuery}) =>  {
          
          if (searchQuery) {
         return   createRequest("/exercises/targetList")
            
          } else if (PartBody) {
          return  createRequest(`/exercises/bodyPart/${PartBody}`)
          // return  createRequest(`/exercises/bodyPart/back`)


          }
          else if (MuscleType) {
            return  createRequest(`/exercises/target/${MuscleType}`)

          }
          else if (EquipmentName) {
            return  createRequest(`/exercises/equipment/${EquipmentName}`)

          }
          
          return   createRequest("/exercises")

          // return  createRequest(`/exercises/target/${SelectPartBody}`)

          
        }
        
        
      }),
      getBodyParts: builder.query({
        query: () => createRequest("/exercises/bodyPartList"),
      }),
      getEquipmentNames: builder.query({
        query: () => createRequest("/exercises/equipmentList"),
      }),
      
      getMuscleType: builder.query({
        query: () => createRequest("/exercises/targetList"),
      }),
      getExerciseById: builder.query({
        query: (id) => createRequest(`/exercises/exercise/${id}`),
      }),
      
     getExerciseByMuscleType: builder.query({
      query:(targetMuscle)=> createRequest(`/exercises/target/${targetMuscle}`),
     }),
     getAllExercisesForSearch: builder.query({
      query:()=> createRequest("/exercises"),
     })


  }),
});

export const { useGetBodyPartsQuery,useGetAllExercisesQuery,useGetMuscleTypeQuery,useGetEquipmentNamesQuery,useGetExerciseByIdQuery ,useGetExerciseByMuscleTypeQuery ,useGetAllExercisesForSearchQuery } = exerciseApi;
