import { makeStyles } from "@mui/styles";
const drawerWidth = 240;

export default makeStyles((theme) => ({
    container: {
        display: "flex",
        height: "100%",
      },
    
      box: {
        height: "70px",
      },
      content: {
        // flexGrow: "1",
        padding: "1em",
        // width: "100%",
      },
}));
